@import "../variables.less";

.layout-wrapper,
.loading-page .p-grid {
  padding: 0;
  min-height: 100vh;
}

.layout-main {
  padding: 16px;
}

.ant-layout-content {
  padding: 15px;
  max-height: calc(100vh - @header-height);
}

@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-sidebar {
        left: calc(100% - @sidebar-width);
      }

      .topbar {
        left: 0;
      }

      .layout-main,
      .layout-footer {
        margin-left: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        .topbar {
          left: 250px;
        }
      }
    }

    &.layout-static {
      .layout-sidebar {
        left: 0;
        top: 58px;
      }

      .topbar {
        left: 0px;
      }

      .layout-main,
      .layout-footer {
        margin-left: @sidebar-width;
      }

      &.layout-static-sidebar-inactive {
        .layout-sidebar {
          left: -250px;
        }

        .topbar {
          left: 0;
        }

        .layout-main,
        .layout-footer {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .topbar {
      left: 0;
    }

    .layout-main,
    .layout-footer {
      margin-left: @sidebar-width;
    }

    .layout-sidebar {
      left: -250px;
      margin-top: 50px;
    }

    .layout-mask {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50px;
      left: 0;
      z-index: 998;
      background-color: @mask-bg-color;
      @include opacity(0.7);
    }

    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        left: -0;
      }

      .layout-mask {
        display: block;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}

.icon-app-btn {
  min-height: 40px;
  width: 100%;
  margin: 5px 0;
  padding: 0 0 0 5px;
  background: transparent;
  border: none !important;
  box-shadow: none !important;
}

.disable-btn {
  color: rgba(0, 0, 0, 0.3) !important;
  background: #f5f5f556 !important;
  text-shadow: none;
}

.disable-btn .ant-btn:hover {
  background: #f5f5f5 !important;
}

.preview-img-container .ant-alert-info {
  margin: 6px 0 10px 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(26, 108, 162, 0.2);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.preview-img-modal .ant-modal-body {
  padding: 5px;
}

.preview-img-modal .ant-modal-content {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(26, 108, 162, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.preview-img-modal .ant-modal-footer {
  border: none;
  padding: 4px 8px;
}
.preview-img-modal .ant-modal-footer button:nth-child(1) {
  display: none;
}

.preview-img-modal .ant-modal-header {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(26, 108, 162, 0.2);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 9px 20px;
}
.preview-img-modal .ant-modal-close-x {
  width: 50px !important;
  line-height: 43px !important;
}

.preview-img-container .slick-dots li button {
  height: 10px;
  background: rgb(75, 75, 75);
}

.site-layout .site-layout-background {
  background: #fff;
}

.antd-sider {
  background: rgb(72, 118, 174);
  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
}

.ant-layout {
  position: relative;
  min-height: calc(100vh - 64px);
}

.ant-layout-sider-collapsed {
  flex: 0 0 55px !important;
  min-width: 55px !important;
  max-width: 55px !important;
  width: 55px !important;
}

.ant-layout-sider-trigger {
  width: 55px !important;
}

.wrapper {
  margin: 16px;
}

.ant-menu-title-content div,
.ant-menu-submenu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.ant-alert{
  padding: 4px 15px;
}

.entity-table-card-layout{
  padding: 20px;
  padding-left:80px;

  .table-card{
    min-height: 604px;
  }
}


.flex-box {
  display: flex;
  flex-direction: column;

  &.entity-detail__content {
    width: 100%;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;

    > .p-card.p-component {
      height: calc(100vh - 262px);
      overflow: auto;
    }
  }

  &.entity-detail__footer {
    margin-bottom: 0;
    padding-top: 5px;
    width: 100%;
  }
}

.content-company{
  padding-left: 80px;
}
