.company-detail__div {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ant-card-bordered {
  box-shadow: @box-shadow;
}



.title--no-margin {
  margin: 0 !important;
}

.delete-company-button {
  color: @white;
  background: @error-color;
}

.delete-company-alert .ant-alert-action {
  margin-top: 10px;
}

.async-with-modal {
  display: flex;
}
