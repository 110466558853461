@header-height: 64px;
@scrollbar-box-shadow-color: rgba(0, 0, 0, 0.2);

* {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

html {
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
  color: @text-color;
  background-color: @body-bg-color;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

body .p-component {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: @font-size;
}

img {
  max-width: 100%;
}

.ant-input-number-handler-wrap {
  display: none;
}

.custom-scrollbar {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #dacccc;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px @scrollbar-box-shadow-color;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px @scrollbar-box-shadow-color;
    background-color: #f5f5f5;
  }
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-small {
  font-size: 12px;
}

.ant-space-vertical {
  width: 100%;
}
