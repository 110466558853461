.companyImport .ant-collapse {
  border: none !important;
}

.companyImport .ant-collapse > .ant-collapse-item {
  border: none !important;
  background: #fff;
}

.companyImport .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #3467a6;
  font-size: 1.2rem;
  background: #fff;
  padding: 0;
  width: 100%;
}

.companyImport .ant-collapse-item:last-child > .ant-collapse-content {
  border: none !important;
}

.uploadFile .ant-upload-text-icon .anticon-loading svg {
  display: none;
}

.import-cycles {
  margin-bottom: 10px;
}

.custom-card .ant-row .align-center {
  display: flex;
  align-self: center;
}

.ant-progress-bg,
.ant-progress-success-bg {
  transition: all 2s cubic-bezier(0.08, 0.82, 0.2, 1) 0s !important;
}

.export-table .ant-table-row {
  .ant-table-cell.background-cell-primary {
    padding: 2px !important;
  }
}
